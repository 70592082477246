
<template>
  <div>
    <div class="card">
      <h6 class="card-header">Datos personales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="nombre">Nombre(s)</label>
                <div class="form-info">
                  {{ ae.nombre }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop">Apellido paterno</label>
                <div class="form-info">
                  {{ ae.apellidop }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom">Apellido materno</label>
                <div class="form-info">
                  {{ ae.apellidom }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="sexo">Sexo</label>
                <div class="form-info">
                  {{ ae.sexo == 'H' ? 'Hombre' : 'Mujer' }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="parroquia">Parroquia</label>
                <div class="form-info">
                  {{ ae.parroquia }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac">Fecha de nacimiento</label>
                <div class="form-info">
                  {{ ae.fecha_nac }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ordenacion">Fecha de ordenación</label>
                <div class="form-info">
                  {{ ae.ordenacion }}
                </div>
              </div>
              <!--          <div class="form-group col-md-3">
            <label for="grado_estudio_id">Grado de estudios</label>
            <select v-model="ae.grado_estudio_id" class="form-control">
              <option
                v-for="grado in gradoestudios"
                :value="grado.grado_estudio_id"
                :key="grado.grado_estudio_id"
              >
                {{ grado.nom_grado }}
              </option>
            </select>
            <div
              class="errores"
              v-if="!$v.ae.grado_estudio_id.required"
            >
              Requerido
            </div>
          </div> -->
              <div class="form-group col-md-3">
                <label for="titulo_eclesiastico">Título eclesiástico</label>
                <div class="form-info">
                  {{ ae.titulo_eclesiastico }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular">Celular</label>
                <div class="form-info">
                  {{ ae.celular }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="correo">Correo electrónico</label>
                <div class="form-info">
                  {{ ae.correo }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <img
              :src="ae.foto instanceof Object ? `${API_URL}/archivo/${ae.foto.ruta}` : ae.foto"
              width="100%" :style="'max-width: 130px;'" class="img-responsive" />
          </div>                    
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <div class="form-info">
              {{ ae.calle }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <div class="form-info">
              {{ estados.filter(s => s.estado_id == ae.estado_id)[0]?.nom_estado }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <div class="form-info">
              {{ municipios.filter(s => s.municipio_id == ae.municipio_id)[0]?.nom_municipio }}
            </div>              
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <div class="form-info">
              {{ localidades.filter(s => s.localidad_id == ae.localidad_id)[0]?.nom_localidad }}
            </div>              

          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <div class="form-info">
              {{ ae.codigo_postal }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <div class="form-info">
              {{ ae.telefono_domicilio }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { API_URL } from "@/api";
export default {
  name: "PerfilAe",
  components: {  },    
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_URL: API_URL,      
      estados: [],
      municipios: [],
      localidades: [],
      ae: {},
      estado: 1,
      fase: 1,
      hijos: [],
      gradoestudios: [],
    };
  },
  methods: {
    valido: function () {
      this.$v.ae.$touch();
      return !this.$v.ae.$invalid;
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.ae.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.ae.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.ae.localidad_id;
      let ae = this.ae;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          ae.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaAE: function () {
      this.ae.estado_id != null && this.actualizaMunicipios();
      this.ae.municipio_id != null && this.actualizaLocalidades();
      this.ae.localidad_id != null && this.actualizaCp();
    },
  },
  computed: {},
  mounted: function () {
    this.ae = this.value;
    this.inicializaAE();
    this.listaEstados();
    this.listaGradoEstudios();
  },
};
</script>
