<!--REVISAR EL CAMPO TELEFONO DOMICILIO DEL JOVEN, tenía referencia telefono_domicilio
pero ese campo ya estaba ocupado por el celular, así que cambié la refencia a "tel_domicilio" -->
<template>
  <div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos personales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nombre">Nombre(s)</label>
                <div class="form-info">
                  {{ joven.nombre }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop">Apellido paterno</label>
                <div class="form-info">
                  {{ joven.apellidop }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom">Apellido materno</label>
                <div class="form-info">
                  {{ joven.apellidom }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="sexo">Sexo</label>
                <div class="form-info">
                  {{ joven.sexo == 'H' ? 'Hombre' : 'Mujer' }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac">Fecha de nacimiento</label>
                <div class="form-info">
                  {{ joven.fecha_nac }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="pasatiempo">Pasatiempo</label>
                <div class="form-info">
                  {{ joven.pasatiempo }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_id">Grado de estudios</label>
                <div class="form-info"> 
                  {{ gradoestudios.filter(s => s.grado_estudio_id == joven.grado_estudio_id)[0]?.nom_grado }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular">Celular</label>
                <div class="form-info">
                  {{ joven.celular }}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo">Correo electrónico</label>
                <div class="form-info">
                  {{ joven.correo }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="parroquia">Parroquia a la que asiste</label>
                <div class="form-info">
                  {{ joven.parroquia }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <img
              :src="joven.foto instanceof Object ? `${API_URL}/archivo/${joven.foto.ruta}` : joven.foto"
              width="100%" :style="'max-width: 130px;'" class="img-responsive" />
          </div>           
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos de los Padres</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="nom_padre">Nombre completo del Padre o Tutor</label>
            <div class="form-info">
                  {{ joven.nom_padre }}
                </div>
          </div>
          <div class="form-group col-md-3">
            <label for="correo_padre">Correo electrónico Padre</label>
            <div class="form-info">
                  {{ joven.correo_padre }}
                </div>
          </div>
          <div class="form-group col-md-3">
            <label for="tel_padre">Celular Padre</label>
            <div class="form-info">
                  {{ joven.tel_padre }}
                </div>
          </div>
          <div class="form-group col-md-6">
            <label for="nom_madre">Nombre completo de la Madre</label>
            <div class="form-info">
                  {{ joven.nom_madre }}
                </div>
          </div>
          <div class="form-group col-md-3">
            <label for="correo_madre">Correo electrónico Madre</label>
            <div class="form-info">
                  {{ joven.correo_madre }}
                </div>
          </div>
          <div class="form-group col-md-3">
            <label for="tel_madre">Celular Madre</label>
            <div class="form-info">
                  {{ joven.tel_madre }}
                </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <div class="form-info">
              {{ joven.calle }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <div class="form-info">
              {{ estados.filter(s => s.estado_id == joven.estado_id)[0]?.nom_estado }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <div class="form-info">
              {{ municipios.filter(s => s.municipio_id == joven.municipio_id)[0]?.nom_municipio }}
            </div>              
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <div class="form-info">
              {{ localidades.filter(s => s.localidad_id == joven.localidad_id)[0]?.nom_localidad }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <div class="form-info">
              {{ joven.codigo_postal }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <div class="form-info">
              {{ joven.telefono_domicilio }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { API_URL } from "@/api";
export default {
  name: "PerfilJoven",
  components: {  },    
  props: {
    value: Object, 
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_URL: API_URL,  
      estados: [],
      municipios: [],
      localidades: [],
      joven: {},
      estado: 1,
      fase: 1,
      hijos: [],
      gradoestudios: [],
    };
  },
  methods: {
    valido: function () {
      this.$v.joven.$touch();
      return !this.$v.joven.$invalid;
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.joven.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.joven.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.joven.localidad_id;
      let joven = this.joven;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          joven.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaJoven: function () {
      this.joven.estado_id != null && this.actualizaMunicipios();
      this.joven.municipio_id != null && this.actualizaLocalidades();
      this.joven.localidad_id != null && this.actualizaCp();
    },
  },
  computed: {},
  mounted: function () {
    this.joven = this.value;
    this.inicializaJoven();
    this.listaEstados();
    this.listaGradoEstudios();

  },
};
</script>
