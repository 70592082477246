<template>
  <div id="app" class="min-vh-100 d-flex">
    <link rel="shortcut icon" href="./assets/img/mfc.png" />

    <menu-lateral :menu="menu" v-if="sesionIniciada" />
    <div class="flex-grow-1 min-vh-100 d-flex flex-column" >
      <b-navbar v-if="true"
        toggleable="md"
        variant="light"
        style="clear: both; border-bottom: 1px solid gray"
      >
        <b-navbar-toggle target="nav-menu"></b-navbar-toggle>
        <b-collapse id="nav-menu" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/"><font-awesome-icon icon="home" /></b-nav-item>
            <b-nav-text>Bienvenido {{ nombreCompleto }} </b-nav-text>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="!sesionIniciada" to="/evalua-promotor">Evaluación de promotores</b-nav-item>
            <b-nav-item v-if="!sesionIniciada && ciclo?.permiso_inscribir" to="/registro">Registrarme</b-nav-item>
            <b-nav-item v-if="sesionIniciada" to="/cambiocontrasena">Cambiar mi contraseña</b-nav-item>
            <b-nav-item v-if="sesionIniciada" to="/perfil">Mi perfil</b-nav-item>
            <b-nav-item v-if="sesionIniciada" to="/salir">Salir</b-nav-item>
            <b-nav-item v-if="!sesionIniciada" to="/acceso">Acceso</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <div class="flex-grow-1 container-fluid mt-2 fondo_contenido">
        <router-view  />
      </div>
      <div class="text-right pie">
        <p style="font-size: 0.7rem">
          <a href="#" @click="avisoPrivacidad()" v-b-modal.privacidad><b>Aviso de privacidad</b></a> |   
          <a href="#" @click="avisoUsoImagen" v-b-modal.usoimagen><b>Aviso de uso de imagen</b></a>
        </p>
      </div>
      <snackbar
        baseSize="5rem"
        ref="mensaje"
        :holdTime="5000"
        style="z-index: 1050"
        position="top-center"
      />
      <b-modal size="lg" scrollable ref="privacidad" title="Aviso de privacidad">
        <aviso-privacidad />
        <template #modal-footer="{ hide }">
          <b-button class="float-right" @click="hide()"> Cerrar </b-button>
        </template>
      </b-modal>
      <b-modal size="lg" scrollable ref="usoimagen" title="Aviso de uso de imagen">
        <aviso-imagen />
        <template #modal-footer="{ hide }">
          <b-button class="float-right" @click="hide()"> Cerrar </b-button>
        </template>
      </b-modal>
      <b-modal ref="aviso" v-bind:title="titulomsg">
        {{ avisomsg }}
        <template #modal-footer="{ hide }">
          <b-button class="float-right" @click="hide()"> Cerrar </b-button>
        </template>
      </b-modal>
      <b-modal ref="sesion" title="Control de sesión">
        Su sesión expirará en {{ sesionsr }} segundos...
        <template #modal-footer="{ hide }">
          <b-button class="float-right" @click="renovarSesion()">
            Renovar
          </b-button>
          <b-button class="float-right" @click="hide()"> Cerrar </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import MenuLateral from "@/components/MenuLateral";
import AvisoPrivacidad from "@/components/AvisoPrivacidad";
import AvisoImagen from "@/components/AvisoImagen";
import * as utils from "@/utils";
import * as api from "@/api";
import {Constantes} from "@/api";

export default {
  name: "App",
  components: { MenuLateral, AvisoPrivacidad, AvisoImagen },
  data() {
    return {
      avisomsg: "",
      titulomsg: "",
      sesionsr: api.TIEMPO_SESION,
      controlTiempo: null,
      menu: [
        {
          title: "Miembros",
          icon: "user",
          path: 'membresia',
          child: [
            {
              title: "Matrimonios",
              href: `/membresia/listar/${Constantes.TIPO_MATRIMONIO}`,
              path: 'membresia-matrimonios',
            },
            {
              title: "Jóvenes",
              href: `/membresia/listar/${Constantes.TIPO_JOVEN}`,
              path: 'membresia-jovenes',
            },
            {
              title: "Adolescentes",
              href: `/membresia/listar/${Constantes.TIPO_ADOLESCENTE}`,
              path: 'membresia-adolescentes',
            },
            {
              title: "Mares",
              href: `/membresia/listar/${Constantes.TIPO_MARE}`,
              path: 'membresia-mares',
            },
            {
              title: "Asistentes eclesiales",
              href: `/membresia/listar/${Constantes.TIPO_AE}`,
              path: 'membresia-ae',
            },

          ],
        },
        {
          title: "CBF",
          icon: "book-open",
          path: 'cbf',
          child: [
            {
              title: 'Matrimonios',
              href: '#',
              path: 'membresia-matrimonios',
              child: [
                {
                  title: "Formación de equipos matrimonios",
                  href: `/cbf/equipos/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-equipos-matrimonios',
                },
                {
                  title: "Control de ofrendas matrimonios",
                  href: `/membresia/ofrendas/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-ofrendas-matrimonios',
                },
                {
                  title: "Resumen de evaluaciones matrimonios",
                  href: `/cbf/resumen/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-resumen-matrimonios', 
                },
                {
                  title: "Resumen de Evaluaciones Equiperos",
                  href: `/cbf/eva-equiperos/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-eva-equiperos-mat',
                },
                {
                  title: "Resumen de Evaluaciones Promotores",
                  href: `/cbf/eva-promotores/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-eva-promotores-mat',
                },
                {
                  title: "Resumen de Evaluaciones de reuniones",
                  href: `/cbf/reuniones/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-reuniones-mat',
                },
                {
                  title: "Próximas reuniones CBF",
                  href: `/cbf/proximas/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'cbf-prox-reuniones-mat',
                },
                {
                  title: "Confirmar posibles bajas de matrimonios",
                  href: `/cbf/bajas/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'membresia-matrimonios-baja',
                },
                {
                  title: "Reportar posibles bajas de matrimonios",
                  href: `/cbf/bajas/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'membresia-matrimonios-posible_baja',
                },
                {
                  title: "Evaluación S05 matrimonios",
                  href: `/cbf/s05/${Constantes.TIPO_MATRIMONIO}`,
                  path: 'matrimonios-eva-s05',
                },
                {
                  title: "Directorio S04 matrimonios",
                  href: `/cbf/s04/${Constantes.TIPO_MATRIMONIO}`,
                  path: '-',
                },
              ]
            },
            {
              title: 'Mares',
              href: '#',
              path: 'membresia-mares',
              child: [
                {
                  title: "Formación de equipos mares",
                  href: `/cbf/equipos/${Constantes.TIPO_MARE}`,
                  path: 'cbf-equipos-mares',
                },
                {
                  title: "Control de ofrendas mares",
                  href: `/membresia/ofrendas/${Constantes.TIPO_MARE}`,
                  path: 'cbf-ofrendas-mares',
                },
                {
                  title: "Resumen de evaluaciones mares",
                  href: `/cbf/resumen/${Constantes.TIPO_MARE}`,
                  path: 'cbf-resumen-mares',
                },
                {
                  title: "Evaluación S05 mares",
                  href: `/cbf/s05/${Constantes.TIPO_MARE}`,
                  path: 'mares-eva-s05',
                },
              ]
            },
            {
              title: 'Jóvenes',
              href: '#',
              path: 'membresia-jovenes',
              child: [
                {
                  title: "Formación de equipos jóvenes",
                  href: `/cbf/equipos/${Constantes.TIPO_JOVEN}`,
                  path: 'cbf-equipos-jovenes',
                },
                {
                  title: "Control de ofrendas jóvenes",
                  href: `/membresia/ofrendas/${Constantes.TIPO_JOVEN}`,
                  path: 'cbf-ofrendas-jovenes',
                },
                {
                  title: "Resumen de evaluaciones jóvenes",
                  href: `/cbf/resumen/${Constantes.TIPO_JOVEN}`,
                  path: 'cbf-resumen-jovenes',
                },
                {
                  title: "Evaluación S05 jóvenes",
                  href: `/cbf/s05/${Constantes.TIPO_JOVEN}`,
                  path: 'jovenes-eva-s05',
                },
              ]
            },
            {
              title: 'Adolescentes',
              href: '#',
              path: 'membresia-adolescentes',
              child: [
                {
                  title: "Formación de equipos adolescentes",
                  href: `/cbf/equipos/${Constantes.TIPO_ADOLESCENTE}`,
                  path: 'cbf-equipos-adolescentes',
                },
                {
                  title: "Control de ofrendas adolescentes",
                  href: `/membresia/ofrendas/${Constantes.TIPO_ADOLESCENTE}`,
                  path: 'cbf-ofrendas-adolescentes',
                },
                {
                  title: "Resumen de evaluaciones adolescentes",
                  href: `/cbf/resumen/${Constantes.TIPO_ADOLESCENTE}`,
                  path: 'cbf-resumen-adolescentes',
                },
                {
                  title: "Evaluación S05 adolescentes",
                  href: `/cbf/s05/${Constantes.TIPO_ADOLESCENTE}`,
                  path: 'adolescentes-eva-s05',
                },
              ]
            },
          ],
        },
        {
          title: "Eventos",
          icon: "calendar-event",
          path: 'eventos',
          child: [
            {
              title: 'Matrimonios',
              href: '#',
              path: 'membresia-matrimonios',
              child: [
                {
                title: "Capacitaciones",
                href: `/eventos/listar/1/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventos-cap',
              },
              {
                title: "Momentos Fuertes",
                href: `/eventos/listar/2/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventos-mf',
              },
              {
                title: "Reuniones Generales",
                href: `/eventos/listar/3/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventos-gral',
              },
              {
                title: "Reuniones Funcionales",
                href: `/eventos/listar/4/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventos-fun',
              },
              {
                title: "Servicios Institucionales de Área II",
                href: `/eventos/listar/6/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventos-a2',
              },
              {
                title: "Nacional",
                href: `/eventos/listar/9/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventos-nac',
              },            
              {
                title: "No Oficiales",
                href: `/eventosno/listar/${Constantes.TIPO_MATRIMONIO}`,
                path: 'eventosno',
              },    
              ]
            },
            {
              title: 'Mares',
              href: '#',
              path: 'membresia-mares',
              child: [
              {
                title: "Capacitaciones",
                href: `/eventos/listar/1/${Constantes.TIPO_MARE}`,
                path: 'eventos-cap',
              },
              {
                title: "Momentos Fuertes",
                href: `/eventos/listar/2/${Constantes.TIPO_MARE}`,
                path: 'eventos-mf',
              },
              {
                title: "Reuniones Generales",
                href: `/eventos/listar/3/${Constantes.TIPO_MARE}`,
                path: 'eventos-gral',
              },
              {
                title: "Reuniones Funcionales",
                href: `/eventos/listar/4/${Constantes.TIPO_MARE}`,
                path: 'eventos-fun',
              },
              {
                title: "Servicios Institucionales de Área II",
                href: `/eventos/listar/6/${Constantes.TIPO_MARE}`,
                path: 'eventos-a2',
              },
              {
                title: "Nacional",
                href: `/eventos/listar/9/${Constantes.TIPO_MARE}`,
                path: 'eventos-nac',
              },            
              {
                title: "No Oficiales",
                href: `/eventosno/listar/${Constantes.TIPO_MARE}`,
                path: 'eventosno',
              },  
              ]
            },
            {
              title: 'Jóvenes',
              href: '#',
              path: 'membresia-jovenes',
              child: [
              {
                title: "Capacitaciones",
                href: `/eventos/listar/1/${Constantes.TIPO_JOVEN}`,
                path: 'eventos-cap',
              },
              {
                title: "Momentos Fuertes",
                href: `/eventos/listar/2/${Constantes.TIPO_JOVEN}`,
                path: 'eventos-mf',
              },
              {
                title: "Reuniones Generales",
                href: `/eventos/listar/3/${Constantes.TIPO_JOVEN}`,
                path: 'eventos-gral',
              },
              {
                title: "Reuniones Funcionales",
                href: `/eventos/listar/4/${Constantes.TIPO_JOVEN}`,
                path: 'eventos-fun',
              },
              {
                title: "Servicios Institucionales de Área II",
                href: `/eventos/listar/6/${Constantes.TIPO_JOVEN}`,
                path: 'eventos-a2',
              },
              {
                title: "Nacional",
                href: `/eventos/listar/9/${Constantes.TIPO_JOVEN}`,
                path: 'eventos-nac',
              },            
              {
                title: "No Oficiales",
                href: `/eventosno/listar/${Constantes.TIPO_JOVEN}`,
                path: 'eventosno',
              },
              ]
            },
          ],
        },
        {
          title: "Actas de reunión",
          icon: "spreadsheet",
          path: 'actas',
          child: [
            {
              title: "Actas diocesanas de matrimonios",
              href: `/actas/listar/${Constantes.TIPO_MATRIMONIO}/${Constantes.JERARQUIA_DIOCESANO}`,
              path: 'actasd-matrimonios',
            },
            {
              title: "Actas diocesanas de jóvenes",
              href: `/actas/listar/${Constantes.TIPO_JOVEN}/${Constantes.JERARQUIA_DIOCESANO}`,
              path: 'actasd-jovenes',
            },
            {
              title: "Actas diocesanas de mares",
              href: `/actas/listar/${Constantes.TIPO_MARE}/${Constantes.JERARQUIA_DIOCESANO}`,
              path: 'actasd-mares',
            },
            {
              title: "Actas de sector de matrimonios",
              href: `/actas/listar/${Constantes.TIPO_MATRIMONIO}/${Constantes.JERARQUIA_SECTOR}`,
              path: 'actass-matrimonios',
            },
            {
              title: "Actas de sector de jóvenes",
              href: `/actas/listar/${Constantes.TIPO_JOVEN}/${Constantes.JERARQUIA_SECTOR}`,
              path: 'actass-jovenes',
            },
            {
              title: "Actas de sector de mares",
              href: `/actas/listar/${Constantes.TIPO_MARE}/${Constantes.JERARQUIA_SECTOR}`,
              path: 'actass-mares',
            },
            {
              title: "Actas región",
              href: `/actas_region/listar`,
              path: 'actas-region',
            },
          ],
        },
        {
          title: "Reportes",
          icon: "table",
          path: 'reportes',
          child: [
            {
              title: "Reportes matrimonios",
              href: `/reportes/${Constantes.TIPO_MATRIMONIO}`,
              path: 'reportes-matrimonios',
            },
            {
              title: "Reportes jóvenes",
              href: `/reportes/${Constantes.TIPO_JOVEN}`,
              path: 'reportes-jovenes',
            },
            {
              title: "Reportes mares",
              href: `/reportes/${Constantes.TIPO_MARE}`,
              path: 'reportes-mares',
            },
            {
              title: "Gráficas",
              href: `/graficas`,
              path: '-',
            },
            {
              title: "Directorio de Oficios y Profesiones de Matrimonios",
              href: `/consultas/DirectorioServicios`,
              path: 'consultas-servicios-mat',
            },
          ],
        },
        {
          title: "Evaluaciones",
          icon: "list-check",
          path: 'evalua',
          child: [
            {
              title: "Secretario de sector",
              href: `/evalua/ss`,
              path: 'evalua-ss',
            },
            {
              title: "Presidente diocesano",
              href: `/evalua/pd`,
              path: 'evalua-pd',
            },
          ],
        },        
        {
          title: "Configuración",
          icon: "cog",
          path: 'config',
          child: [
            {
              title: "Contraseñas Equiperos",
              href: "/equiperos",
              path: 'config-equiperos', 
            },            
            {
              title: "Usuarios",
              href: "/usuarios",
              path: 'config-usuarios', 
            },            
            {
              title: "Ciclos",
              href: "/ciclos",
              path: 'config-ciclos',
            },            
            {
              title: "Diócesis",
              href: "/diocesis",
              path: 'config-diocesis',
            },            
            {
              title: "Sectores",
              href: "/sectores",
              path: 'config-sectores',
            },            
            {
              title: "Roles",
              href: "/roles",
              path: 'config-roles',
            },
            {
              title: "Funciones",
              href: "/funciones",
              path: 'config-funciones',
            },
            {
              title: "Bitácora",
              href: "/bitacora",
              path: 'config-bitacora',
            },
          ],
        },
      ],
    };
  },
  methods: {
    error(mensaje) {
      this.$refs.mensaje.error(mensaje);
    },
    mensaje(mensaje) {
      this.$refs.mensaje.info(mensaje);
    },
    aviso(mensaje) {
      this.titulomsg = "Aviso";
      this.avisomsg = mensaje;
      this.$refs.aviso.show();
    },
    confirmar(mensaje) {
      return new Promise((resolve) =>{
      this.$bvModal.msgBoxConfirm(mensaje, {
          title: 'Confirmación',
          okTitle: 'Sí',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value) {
              resolve();
            }
          })        
      });
    },
    permisos(opcion) {
      return (opcion == '-') || (this.$store.getters.permisos && this.$store.getters.permisos.includes(opcion));
    },
    alerta(mensaje) {
      this.titulomsg = "Error";
      this.avisomsg = mensaje;
      this.$refs.aviso.show();
    },
    avisoPrivacidad() {
      this.$refs.privacidad.show();

    },
    avisoUsoImagen() {
      this.$refs.usoimagen.show();

    },
    avisoTiempoSesion() {
      this.sesionsr = api.TIEMPO_SESION;
      this.$refs.sesion.show();
    },
    renovarSesion() {
      this.$refs.sesion.hide();
      this.$store.dispatch("renovarSesion");
    },
    onResize() {
      const width = window.innerWidth - 105;
      //const width = document.getElementById("nav-menu").innerWidth;
      document.documentElement.style.setProperty('--width-table', width + 'px');
      console.log("Resizeado"+window.innerWidth);
    }

  },
  computed: {
    ciclo() {
      return this.$store.getters.ciclo;
    },

    usuario() {
      return this.$store.getters.usuario;
    },
    nombreCompleto() {
      if (this.$store.getters.sesionIniciada()) {
        let usuario = this.$store.getters.usuario;
        if (usuario != null) {
          return usuario.nombre_completo;
        }
      }
      return "Invitado";
    },
    rolCompleto() {
      if (this.$store.getters.sesionIniciada()) {
        let usuario = this.$store.getters.usuario;
        if (usuario != null) {
          return usuario.rol.nom_rol;
        }
      }
      return "Invitado";
    },
    sesionIniciada() {
      return this.$store.getters.sesionIniciada();
    },
    esAdministrador() {
      return this.$store.getters.esAdmin;
    },
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted() {
    this.$store.dispatch("restaurarSesion");
    this.$store.dispatch("consultarCiclo");
    this.$store.dispatch("consultarFiltros");
    utils.EventBus.$on("sesion-iniciada", () => {
      /*if (this.controlTiempo != null) {
          clearTimeout(this.controlTiempo);
      }
      this.controlTiempo = setTimeout(() => this.avisoTiempoSesion(), 1 * 60 * 1000); //TODO: Tiempo de la sesión
      */
     console.log("sesion-iniciada")
    });
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })    
  },
};
</script>
<style>
  :root {
    --width-table: 80px;
  }
  .vue-table-root {
    width: var(--width-table);
  }
</style>
