<template>
  <div>
    <div class="card">
      <h2 class="card-header">Reporte de Inconsistencias</h2>
      <div> <!-- Encabezado del reporte -->
        <table id="mi-tabla">
          <tbody>
            <tr>
              <th class="titulo" id="tabla-encabezado">Diócesis:</th>
              <td> {{ reporte[0].nom_diocesis }} </td>
            </tr>
            <tr>
              <th class="titulo" id="tabla-encabezado">Sector:</th>
              <td> {{ reporte[0].nom_sector }} </td>
            </tr>
            <tr>
              <th class="titulo" id="tabla-encabezado">Ciclo:</th>
              <td> <!--{{ reporte[0].ciclo }}--> </td>
            </tr>
          </tbody>
        </table>
      </div> <!-- Fin del contendor Encabezado del Reporte -->

      <div id="contenedor"> <!-- Contenedor Principal --> 
        <div class="card-body"> <!--Datos de Inconsistencias-->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <td style = "display:inline-table"></td>
                  <th>Total de Registros</th>
                  <th>Registros Inconsistentes</th>
                  <th>Registros Correctos</th>
                  <th>Porcentaje correcto (%)</th>
                </tr>
              </thead>
              <tbody v-for="reporte in reportes" :key="reporte.membresia_id">
                <tr> <!--Inconsistencias-->
                  <td scope="TituloInconsistencia"> <!--{{ reporte.NombreInconsistencia }}--> </td>
                  <td class="text-center"> <!--{{ reporte.Total }}--> </td>
                  <td class="text-center"> <!--{{ reporte.NoDeInconsistencias }}--> </td>
                  <td class="text-center"> <!--{{ reporte.Correctos }}--> </td>
                  <td class="text-center"> <!--{{ reporte.Porcentaje }}--> </td>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row Tabla -->
        </div> <!-- card-body Datos principales Él y Ella -->
      </div>  <!-- Contenedor Principal-->

      <div class="card-body"> <!-- Tabla de Rangos -->
          <div class="form-row">
            <table>
              <thead>
                <tr>
                  <th colspan="4" style="text-align: center;" scope="rowFondoNegro">Tabla de Rangos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style="text-align: center; width: 15%; background-color: rgb(248, 181, 181);">Menos de 50%</th>
                  <th style="text-align: center; width: 15%; background-color: rgb(159, 209, 250);">De 51% a 80%</th>
                  <th style="text-align: center; width: 15%; background-color: rgb(255, 255, 159);">De 81% a 90%</th>
                  <th style="text-align: center; width: 15%; background-color: rgb(199, 250, 199);">De 91% a 100%</th>
                </tr>
              </tbody>
            </table>
          </div> <!-- form-row form-row Tabla-->
        </div> <!-- card-body Tabla de Rangos -->

    </div> <!-- Del Card principal-->
  </div> <!-- Del Template-->
</template>

<script>
import api from "@/api";
import { Constantes } from "@/api";
import moment from "moment";

export default {
  name: "ActasConsultar",
  components: {  },
  data() {
    return {
      Constantes: Constantes,
      reportes: {},
      guardando: false,
      permisos: {
        agregar: ["", "acta-agregar"],
        editar: ["", "acta-editar"],
        estatus: ["", "acta-estatus"],
      },
    };
  },
  //  };
  methods: {
    getFormattedDate(date) {
        return moment(date).format("DD-MM-YYYY")
    },
    reporteS04: function (sector) { //ReporteInconsistencias
      api.reporteS04(sector).then((r) => { //ReporteInconsistencias
        this.reporte = r.data; 
      });
    },

  },
  computed: {},
  mounted: function () {
    const sector = this.$store.getters.usuario.sector.sector_id;

    this.reporteS04(sector); //ReporteInconsistencias
  },
};
</script>

<style scoped>
.acuerdo-number {
  font-size: 1.2em; /* Tamaño de fuente más grande */
  color: #007bff; /* Color de resaltado, puedes ajustarlo a tu preferencia */
  font-weight: bold; /* Texto en negrita */
}

.underlined-text {
  text-decoration: underline; /* Subrayar el texto */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

#tabla-encabezado {
  width: 15%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  text-align: right; /* Alineación a la derecha */
}

#tabla-RolPrincipal {
  width: 52%;
  padding: 20px; /* Espacio para otros contenidos o espacio en blanco */
  
  text-align: right; /* Alineación a la derecha */
}

#mi-tabla {
  width: 100%;
  border-collapse: collapse;
}

#mi-tabla th, #mi-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align:left;
}

#mi-tabla th {
  background-color: #f2f2f2;
}

.titulo {
  font-weight: bold;
}

#contenedor {
  width: 100%;
  border: 1px solid #cccc;
  padding: 10px;
  position: relative;
  align-self: center;
}

#cuadro-firma {
  width: 100%;
  height: 150px; /* Ajusta la altura según tus necesidades */
  border: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#linea-firma, #linea-cargo {
  width: 80%; /* Ajusta el ancho según tus necesidades */
  border-top: 1px solid #000;
  margin-top: 25px;
}

#label-el, #label-cargo {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

tr td[scope="TituloInconsistencia"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: right;
  width: 20%;
}

tr th[scope="rowFondoNegro"] {
  background-color: #585858;
  color: white;
  font-weight: bold;
  text-align: left;
}

</style>
