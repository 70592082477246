<template>
  <div>
    <h4>Eventos: {{ titulo }}</h4>
    <filtros-eventos
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo_membresia"
      :mostrar-tipo-membresia="false"
 
      @input="actualizar"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoCapacitaciones.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoCapacitaciones.length == 0">Exportar Excel</b-button>
        <b-button :to="`/eventos/agregar/${tipo}/${tipo_membresia}/`">Agregar</b-button>
      </div>
    </div>
    <ve-table 
      rowKeyFieldName="evento_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoCapacitaciones"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosEventos from "@/components/FiltrosEventos.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"

export default {
  name: "Evento",
  components: { FiltrosEventos },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoCapacitaciones: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      tipo_membresia: Number.parseInt(this.$route.params.tipo_membresia),
      titulo:
        Constantes.TIPOS_EVENTOS[Number.parseInt(this.$route.params.tipo)],
      permisos: {
        agregar: ['','eventos-cap-agregar','eventos-mf-agregar', 'eventos-gral-agregar', 'eventos-fun-agregar', '', 'eventos-a2-agregar','', '', 'eventos-nac-agregar'],
        editar: ['','eventos-cap-editar','eventos-mf-editar', 'eventos-gral-editar', 'eventos-fun-editar', '', 'eventos-a2-editar','', '', 'eventos-nac-editar'],
        eliminar: ['','eventos-cap-eliminar','eventos-mf-eliminar', 'eventos-gral-eliminar', 'eventos-fun-eliminar', '', 'eventos-a2-eliminar','', '', 'eventos-nac-eliminar'],
      },
      busqueda: {},
      columnas: [
        {
          field: "evento_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nombre",
          key: "d",
          title: "Nombre",
          align: "left",
        },
        {
          field: "estado",
          key: "estado",
          title: "Estado",
          align: "left",
        },
        {
          field: "fecha_ini",
          key: "e",
          title: "Fecha inicio",
          align: "left",
        },
        {
          field: "asistentes",
          key: "asist",
          title: "Asistentes",
          align: "left",
        },
        {
          field: "duracion",
          key: "f",
          title: "Duración",
          align: "left",
        },
        {
                
          key: "lugar",
          title: "Lugar",
          align: "center",
          field: 'lugar',
          
        },        
        {
          field: "ciclo",
          key: "ciclo",
          title: "Ciclo",
          align: "center",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <router-link to={"/eventos/editar/" + d.row.evento_id} v-show={this.$parent.permisos(this.permisos.editar[this.tipo])}>
                  <font-awesome-icon size="lg" icon="edit"   />
                </router-link>
                <router-link to={"/eventos/consultar/" + d.row.evento_id} >
                  <font-awesome-icon size="lg" icon="file-alt"   />
                </router-link>
                <a href="javascript:;" onClick={() => this.eliminar(d.row)} v-show={this.$parent.permisos(this.permisos.eliminar[this.tipo])}>
                  <font-awesome-icon size="lg" icon="trash" />
                </a>
                <a
                  target="_blank"
                  href={"/eventos/qr/" + d.row.evento_id }
                >
                  <font-awesome-icon size="lg" icon="qrcode"  />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {};
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoCapacitaciones);
    },
    actualizar() {
      api.listarEventos(this.tipo, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoCapacitaciones = r.data;
        } else {
          this.listadoCapacitaciones = [];
        }
      });
    },
    eliminar(elemento) {
      this.$parent
        .confirmar("Confirma eliminar el evento: " + elemento.nombre)
        .then(() => {
          api
            .eliminarEvento(elemento.evento_id)
            .then((resp) => {
              let r = resp.data;
              if (r.respuesta) {
                this.actualizar();
              } else {
                this.$parent.aviso(r.mensaje);
              }
            })
            .catch((error) => {
              this.$parent.aviso("Error al eliminar " + error);
            });
        });
    },
    guardar() {
      this.$v.sector.$touch();
      if (!this.$v.sector.$invalid) {
        api
          .guardarSector(this.sector)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarSectores();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },

    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.tipo_membresia = Number.parseInt(this.$route.params.tipo_membresia);
        this.titulo = Constantes.TIPOS_EVENTOS[this.tipo];
        this.busqueda = {};
        this.listadoCapacitaciones = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);
      }
    );
  },
};
</script>

