<template>
  <div>
  <transition name="modal">
      <div class="modal" v-show="showModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <b-modal size="lg" v-model="showModal" title="Rangos de evaluación">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="8" class="aspecto-cell">Calificación</th>
                  </tr>
                  <tr>
                    <th class="aspecto-cell">Aspecto</th>
                    <th class="aspecto-cell">0</th>
                    <th class="aspecto-cell">5</th>
                    <th class="aspecto-cell">6</th>
                    <th class="aspecto-cell">7</th>
                    <th class="aspecto-cell">8</th>
                    <th class="aspecto-cell">9</th>
                    <th class="aspecto-cell">10</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.aspecto">
                    <td class="aspecto-cell">{{ row.aspecto }}</td>
                    <td v-for="calificacion in row.calificaciones" :key="calificacion">{{ calificacion }}</td>
                  </tr>
                </tbody>
              </table>
              <template #modal-footer>
                <b-button variant="primary" @click="cerrarModal">Cerrar</b-button>
              </template>
            </b-modal>
          </div> <!-- Modal-Content-->
        </div> <!-- Modal-Dialog -->
      </div> <!-- Modal -->
    </transition>

    <h4>Registro S05</h4>
    <b-card header="Equipos">
      <form @submit.prevent="actualizarMembresia">
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Equipo a evaluar">
              <b-form-select :disabled="evaluando"
                v-model="equipo" @change="actualizarTemas"
              >
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
                <b-form-select-option
                  v-for="equipo in equipos"
                  :key="equipo.id"
                  :value="equipo"
                  >{{`${equipo.nom_rol}, ${equipo.nom_sector}, ${equipo.nom_diocesis}, NIVEL: ${equipo.nom_nivel} ZONA: ${equipo.zona} ${equipo.zonal ? '' : 'EQUIPO: '+equipo.num_equipo}`}}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
      </form>
    </b-card>
    <br />
    <b-card header="Temas" v-show="!evaluando && equipo != null">
        <b-form-row>
          <b-col md="12">
            <table class="table table-bordered">
              <thead>
                <th>Tema</th>
                <th>Estatus</th>
                <th>Evaluaciones</th>
                <th>Acciones</th>
              </thead>
              <tr v-for="tema in temas" :key="tema.tema.tema_id">
                <td>{{ tema.tema.nom_tema }}</td>
                <td>{{ tema.estatus }}</td>
                <td>{{ tema.cantidad ?? 0 }}</td>
                <td><a href="javascript:;" @click="evaluar(tema)"><font-awesome-icon icon="tasks" /></a></td>
              </tr>
            </table>
          </b-col>
        </b-form-row>
    </b-card>
    <br />
    <form @submit.prevent="guardar"> 
      <div class="card" v-if="evaluando">
        <h6 class="card-header">Reunión {{ tema.tema.nom_tema }}</h6>
        <div class="card-body">
          <div class="form-row">
            
            <div class="form-group col-md-3">
              <label for="fecha_ini">Fecha:</label>
              <input
                type="date"
                v-model="tema.reunion.fecha"
                class="form-control"
              />
              <div class="errores" v-if="!$v.tema.reunion.fecha.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-3">

                <label>Anfitrión:</label>
                <b-form-select
                  v-model="tema.reunion.anfitrion_membresia_id"
                  :options="membresias"
                  value-field="membresia_id"
                  text-field="nombre_completo"
                >
                </b-form-select>

              <div class="errores" v-if="!$v.tema.reunion.anfitrion_membresia_id.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="hora_ini">Hora programada:</label>
              <input
                type="time"
                id="hora_ini"
                v-model="tema.reunion.hora_cita"
                class="form-control"
              />
              <div class="errores" v-if="!$v.tema.reunion.hora_cita.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="hora_ini">Hora inicio:</label>
              <input
                type="time"
                id="hora_ini"
                v-model="tema.reunion.hora_inicio"
                class="form-control"
              />
              <div class="errores" v-if="!$v.tema.reunion.hora_inicio.required">
                Requerido
              </div>
            </div>
          
            <div class="form-group col-md-3">
              <label for="hora_fin">Hora de término:</label>
              <input
                type="time"
                id="hora_termino"
                v-model="tema.reunion.hora_termino"
                class="form-control"
              />
              <div class="errores" v-if="!$v.tema.reunion.hora_termino.required">
                Requerido
              </div>
            </div>
            
            <div class="form-group col-md-3">
              <label for="fecha_ini">Fecha próxima reunión:</label>
              <input
                type="date"
                v-model="tema.reunion.fecha_siguiente"
                class="form-control"
              />
              <div class="errores" v-if="!$v.tema.reunion.fecha_siguiente.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="hora_siguiente">Hora siguiente reunión:</label>
              <input
                type="time"
                id="hora_siguiente"
                v-model="tema.reunion.hora_siguiente"
                class="form-control"
              />
              <div class="errores" v-if="!$v.tema.reunion.hora_siguiente.required">
                Requerido
              </div>
            </div>          
            <div class="form-group col-md-3">

                <label>Siguiente anfitrión:</label>
                <b-form-select
                  lab
                  v-model="tema.reunion.siguiente_membresia_id"
                  :options="membresias"
                  value-field="membresia_id"
                  text-field="nombre_completo"
                >
                </b-form-select>

              <div class="errores" v-if="!$v.tema.reunion.siguiente_membresia_id.required">
                Requerido
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="observaciones">Accion sugerida</label>
              <textarea
                v-model="tema.reunion.accion_sugerida"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="observaciones">Observaciones</label>
              <textarea
                v-model="tema.reunion.observaciones"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="observaciones">Necesidades</label>
              <textarea
                v-model="tema.reunion.necesidades"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-6" v-if="tema.reunion.comentarios">
              
              <label for="observaciones">Comentarios de zonal/Area I</label>
              <span class="form-info-texto">{{ tema.reunion.comentarios }}</span>
            </div>
            <b-button @click="showModal = true">Rangos de evaluación</b-button>
          </div>
              <div class="form-row" v-for="(eva, index) in $v.tema.evaluaciones.$each.$iter" :key="eva.$model.membresia_id">
                <div class="col-12" >
                  <table class="table table-bordered">
                    <tr>
                      <th>{{ eva.$model.nombre_completo }}</th>
                      <th> {{ tipo_membresia_id == Constantes.TIPO_MATRIMONIO ? 'El' : tipo_membresia_id == Constantes.TIPO_MARE ? 'Ella' : 'Jóven' }}</th>
                      <th v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">Ella</th>
                    </tr>
                    <tr>
                      <td><label>Puntualidad</label></td>
                      <td>              
                        <b-form-select
                          v-model="eva.$model.puntualidad1"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.puntualidad1.required">
                        Requerido
                      </div>
                      </td>
                      <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                        <b-form-select
                          v-model="eva.$model.puntualidad2"
                          :options="puntos"
                        >
                          <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                        </b-form-select>
                        <div class="errores" v-if="!eva.puntualidad2.required">
                          Requerido
                        </div>
                      </td>              
                    </tr>
                    <tr>
                      <td><label>Estudio</label></td>
                      <td>              
                        <b-form-select
                          v-model="eva.$model.estudio1"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.estudio1.required">
                        Requerido
                      </div>
                      </td>
                      <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                        <b-form-select
                          v-model="eva.$model.estudio2"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.estudio2.required">
                        Requerido
                      </div>
                      </td>              
                    </tr>
                    <tr>
                      <td><label>Participación</label></td>
                      <td>              
                        <b-form-select
                          v-model="eva.$model.participacion1"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.participacion1.required">
                        Requerido
                      </div>
                      </td>
                      <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                        <b-form-select
                          v-model="eva.$model.participacion2"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.participacion2.required">
                        Requerido
                      </div>
                      </td>              
                    </tr>
                    <tr>
                      <td><label>Acción sugerida</label></td>
                      <td>              
                        <b-form-select
                          v-model="eva.$model.accionsugerida1"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.accionsugerida1.required">
                        Requerido
                      </div>
                      </td>
                      <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                        <b-form-select
                          v-model="eva.$model.accionsugerida2"
                          :options="puntos"
                        >
                        <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                      </b-form-select>
                      <div class="errores" v-if="!eva.accionsugerida2.required">
                        Requerido
                      </div>
                      </td>              
                    </tr>            
                    <tr>
                      <td>
                        <div class="form-check">
                          <input class="form-check-input" :id="`tema${index}`" type="checkbox" v-model="eva.$model.tema_familia">
                          <label :for="`tema${index}`">¿Tema en familia? </label>
                        </div>
                      </td>
                      <td>              
                        <b-form-select v-show="eva.$model.tema_familia"
                          v-model="eva.$model.familia1"
                          :options="puntos"
                        >
                          <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                        </b-form-select>
                        <div class="errores" v-if="!eva.familia1.required">
                          Requerido
                        </div>
                      </td>
                      <td v-show="tipo_membresia_id == Constantes.TIPO_MATRIMONIO">
                        <b-form-select v-show="eva.$model.tema_familia"
                          v-model="eva.$model.familia2"
                          :options="puntos"
                        >
                          <b-form-select-option :value="null">Sin evaluar</b-form-select-option>
                        </b-form-select>
                        <div class="errores" v-if="!eva.familia2.required">
                          Requerido
                        </div>
                      </td>              
                    </tr>            
                  </table>
                </div>
                
              </div>
        </div>
        <br />
        <div class="text-center botones-accion">
          <button class="btn btn-primary" type="submit" >Guardar</button>
          <button class="btn btn-secondary" @click="cancelar" >Cancelar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api, { Constantes } from "@/api";
import { required, requiredIf } from "vuelidate/lib/validators";
import { BModal, BButton } from 'bootstrap-vue'

export default {
  name: "Estatus",
  components: {
    BModal,
    BButton
  },
  data() {
    return {
      Constantes: Constantes,
      puntos: [0,5,6,7,8,9,10],
      evaluando: false,
      equipos: [],
      equipo: null,
      tipo_membresia_id: null,
      showModal: false,
      tableHeaders: ['Aspecto a evaluar', '0', '5', '6', '7', '8', '9', '10'],
      tableData: [
        { aspecto: 'Puntualidad',
          calificaciones: ['Más de 20 minutos de retraso/No asistió', 'Hasta 20 minutos de retraso','Hasta 15 minutos de retraso','Hasta 10 minutos de retraso','Hasta 5 minutos de retraso','Llega exacto a la hora','Llega 5 minutos antes de iniciar la reunión'],
        },
        { aspecto: 'Estudio / Reflexión',
          calificaciones: ['No leyó el tema, no realizó las relfexiones / No asistió','Leyó el tema parcialmente','Leyó el tema, pero no realizó ninguna reflexión','Leyó el tema y realizó solo la reflexión personal','Estudió el tema parcialmente, realizó solo la reflexión personal','Estudio el tema e hizo la reflexión personal y conyugal','Estudio el tema y realizó todas las reflexiones'],
        },
        { aspecto: 'Participación',
          calificaciones: ['No asistió','Actitud negativa','Actitud pasiva','Participa interrumpiendo a los demás','Participa sólo si se le pregunta directamente','Participa pero tiende a salirse del tema','Participa libremente, no se sale del tema, habla moderadamente, sabe escuchar'],
        },
        { aspecto: 'Acción Sugerida',
          calificaciones: ['No asistió','No realizó la Acción Sugerida','Realizó la Acción Sugerida de forma incorrecta','Realizó la Acción Sugerida al menos 5 días','Realizó la Acción Sugerida al menos 10 días','Realizó la Acción Sugerida al menos 12 días','Realizó la Acción Sugerida todos los días'],
        },
        { aspecto: 'Tema en familia',
          calificaciones: ['No asistió','No vivió el tema en familia','Sólo leyó el tema de manera parcial','Sólo leyó el tema en familia','Sólo compartió el tema en familia e hicieron Oración','Compartió el tema en familia: Texto, Palabra, Oración, pero sin Reflexión Familiar','Compartió el tema en familia: Texto, Palabra, Oración y Reflexión Familiar'],
        }
      ],
      tema: {
        tema: {},
        reunion: {},
        evaluaciones: []
      },
      temas: [],
      membresias: [],
      equiperos: [],

    };
  },
  validations() {
    return {
      tema: {
        reunion: {
          fecha: {
            required,
          },
          hora_cita: {
            required,
          },
          hora_inicio: {
            required,
          },
          hora_termino: {
            required,
          },
          fecha_siguiente: {
            required,
          },
          hora_siguiente: {
            required,
          },
          anfitrion_membresia_id: {
            required
          },
          siguiente_membresia_id: {
            required
          }
        },
        evaluaciones: {
          $each: {
            puntualidad1: { required },
            puntualidad2: { required: requiredIf( () => this.tipo_membresia_id == Constantes.TIPO_MATRIMONIO) },
            estudio1: { required },
            estudio2: { required: requiredIf( () => this.tipo_membresia_id == Constantes.TIPO_MATRIMONIO) },
            participacion1: { required },
            participacion2: { required: requiredIf( () => this.tipo_membresia_id == Constantes.TIPO_MATRIMONIO) },
            accionsugerida1: { required },
            accionsugerida2: { required: requiredIf( () => this.tipo_membresia_id == Constantes.TIPO_MATRIMONIO) },
            familia1: { required: requiredIf((o) => o.tema_familia) },
            familia2: { required: requiredIf( (o) => this.tipo_membresia_id == Constantes.TIPO_MATRIMONIO && o.tema_familia) },
          },
        },
      }
    };
  },
  methods: {
    cerrarModal() {
      this.showModal = false;
    },
    evaluar(tema) {
      console.log(tema)
      let ideq = {};
      this.tema = tema;
      let reunion = tema.reunion ?? { };
      reunion = Object.assign(reunion, {
        zona: this.equipo.zona,
        num_equipo: this.equipo.num_equipo,
        sector_id: this.equipo.sector_id,
        tema_id: tema.tema.tema_id
      });
      this.tema.reunion = reunion; 
      this.equiperos.forEach(e => {
        ideq[e.membresia_id] = e;
      });

      let evaluaciones = [];
      console.log(this.reunion);
      tema.evaluaciones.forEach(eva => {
        if (eva.membresia_id in ideq) {
          evaluaciones.push(eva);
          eva.nombre_completo = ideq[eva.membresia_id].nombre_completo;
          delete ideq[eva.membresia_id];
        } 
      });

      Object.keys(ideq).forEach(e => {
        evaluaciones.push({
            membresia_id: ideq[e].membresia_id,
            nombre_completo: ideq[e].nombre_completo,
            puntualidad1:null,
            puntualidad2:null,
            estudio1:null,
            estudio2:null,
            participacion1:null,
            participacion2:null,
            accionsugerida1:null,
            accionsugerida2:null,
            familia1:null,
            familia2:null,
            tema_familia: true,
          });

      });
      this.tema.evaluaciones = evaluaciones;
      this.evaluando = true;
    },
    guardar() {
      this.$v.tema.reunion.$touch();
      if (!this.$v.tema.reunion.$invalid) {
        const datos = {reunion: this.tema.reunion, evaluaciones: this.tema.evaluaciones};
        api
          .guardarReunion(datos)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.tema.reunion = r.reunion;
              this.tema.evaluaciones = r.evaluaciones;
              this.tema.cantidad = r.evaluaciones.length;
              this.cancelar();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    cancelar() {
      this.evaluando = false;
    }, 

    actualizarTemas() {
      api.listarTemasCBF(this.equipo.id).then((r) => {
        const resp = r.data;
        if (!resp.respuesta) {
          this.temas = [];
          this.reunion = {};
          this.membresias = [];
          this.equiperos = [];
          this.$parent.error(resp.mensaje);
        } else {
          this.temas = resp.temas; 
          this.reunion = {};
          this.membresias = resp.membresias;
          if (this.equipo.zonal) {
            this.equiperos = this.membresias.filter(m => Constantes.PROMOTORES.includes(m.rol_id));
          } else {
            this.equiperos = this.membresias.filter(m => Constantes.EQUIPEROS.includes(m.rol_id));
          }
        }
      });
    },

    actualizarEquipos() {
      api.listarEquiposCBF().then((r) => {
        const resp = r.data;
        if (!resp.respuesta) {
          this.equipos = [];
          this.$parent.error(resp.mensaje);
        } else {
          this.equipos = resp.equipos; 
          this.tipo_membresia_id = resp.tipo_membresia_id;
        }
      });
    },

  },
  mounted() {
    this.actualizarEquipos();
  },
};
</script>

<style scoped>

  .modal-body {
    overflow-y: auto;
    max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  }

  @media (max-width: 1400px) {
    .table td, .table th {
      font-size: 8px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }
  .table {
    flex: 1;
    overflow-y: auto;
  }

  table, th, td {
      border: 1px solid black;
      border-collapse: collapse;
  }

  .table th:nth-child(2) {
    text-align: center;
    font-weight: bold;
  }

  .aspecto-cell {
    background-color: #f2f2f2; /* Un gris claro */
    text-align: center;
  }

  table thead tr th:first-child {
    background-color: #f2f2f2; /* Un gris claro */
  }

</style>