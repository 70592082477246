<template>
  <div>
    <h4 class="modal-title"><font-awesome-icon icon="user" /> {{ titulo }}</h4>
    <div class="card">
      <h6 class="card-header">Datos de miembro</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="diocesis_id">Diócesis</label>
            <div class="form-info">
              {{ diocesis.filter(s => s.diocesis_id == membresia.diocesis_id)[0]?.nom_diocesis }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="sector_id">Sector o presector</label>
            <div class="form-info">
              {{ sectores.filter(s => s.sector_id == membresia.sector_id)[0]?.nom_sector }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="membresia_id">ID</label>
            <div class="form-info">
              {{ membresia.membresia_id }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label>Estatus</label>
            <div class="form-info">
              {{ estatuses.filter(s => s.estatus_id == membresia.estatus_id)[0]?.nom_estatus }}
            </div>
          </div>
          <div class="col-md-12" v-if="membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE"><strong>Usar el rol
              "EQUIPERO(A)" cuando el adolescente pase a joven</strong></div>
          <div class="form-group col-md-3">
            <label for="rol">Rol Principal</label>
            <div class="form-info">
              {{ roles.filter(s => s.rol_id == membresia.rol_id)[0]?.nom_rol }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="rol">Nivel</label>
            <div class="form-info">
              {{ niveles.filter(s => s.nivel_id == membresia.nivel_id)[0]?.nom_nivel }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="anio_ingreso">Año de Ingreso al MFC</label>
            <div class="form-info">
              {{ membresia.anio_ingreso }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inscripcion">Inscripción</label>
            <div class="form-info">
              {{ membresia.inscripcion }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="ofrenda">Ofrenda mensual</label>
            <div class="form-info">
              {{ membresia.ofrenda }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Roles adicionales</h6>
      <div class="card-body">
        <br />
        <table class="table table-bordered">
          <tr>
            <th>Diócesis</th>
            <th>Sector</th>
            <th>Rol</th>
            <th>Nivel</th>
            <th>Zona</th>
            <th>Equipo</th>
          </tr>
          <tr v-for="rol_adicional in membresia.roles_adicionales" :key="rol_adicional.rol_adicional_id">
            <td>
              <span>{{ rol_adicional.diocesis }}</span>
            </td>
            <td>
              <span>{{ rol_adicional.sector }}</span>
            </td>
            <td>
              <span>{{ rol_adicional.rol }}</span>
            </td>
            <td>
              <span>{{ rol_adicional.nivel }} </span>
            </td>
            <td>
              {{ rol_adicional.zona }}
            </td>
            <td>
              {{ rol_adicional.num_equipo }}
            </td>
          </tr>
          <tr v-if="membresia.roles_adicionales.length == 0">
            <td colspan="6">No hay roles adicionales</td>
          </tr>
        </table>

      </div>
    </div>
    <br />

    <perfil-matrimonio v-model="membresia.matrimonio" :mostrar-foto="true" ref="matrimonio"
      v-if="membresia.tipo_membresia_id == Constantes.TIPO_MATRIMONIO" />
    <perfil-mare v-model="membresia.mare" :mostrar-foto="true" ref="mare"
      v-if="membresia.tipo_membresia_id == Constantes.TIPO_MARE" />
    <perfil-joven v-model="membresia.joven" :mostrar-foto="true" ref="joven" v-if="
      membresia.tipo_membresia_id == Constantes.TIPO_JOVEN ||
      membresia.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE
    " />
    <perfil-ae v-model="membresia.ae" :mostrar-foto="true" ref="ae"
      v-if="membresia.tipo_membresia_id == Constantes.TIPO_AE" />

    <br />
    <div class="card">
      <h6 class="card-header">Historial de apostolados</h6>
      <div class="card-body">
        <br />
        <table class="table table-bordered">
          <tr>
            <th>Ciclo</th>
            <th>Tipo de rol</th>
            <th>Diócesis</th>
            <th>Sector</th>
            <th>Rol</th>
            <th>Nivel</th>
            <th>Zona</th>
            <th>Equipo</th>
          </tr>
          <tr v-for="rol in historialRoles" :key="rol.rol_membresia_id">
            <td>
              <span>{{ rol.ciclo }}</span>
            </td>
            <td>
              <span>{{ rol.tipo }}</span>
            </td>
            <td>
              <span>{{ rol.diocesis }}</span>
            </td>
            <td>
              <span>{{ rol.sector }}</span>
            </td>
            <td>
              <span>{{ rol.rol }}</span>
            </td>
            <td>
              <span>{{ rol.nivel }} </span>
            </td>
            <td>
              {{ rol.zona }}
            </td>
            <td>
              {{ rol.num_equipo }}
            </td>
          </tr>
          <tr v-if="historialRoles.length == 0">
            <td colspan="6">No hay historial de apostolados</td>
          </tr>
        </table>

      </div>
    </div>
    <br>
    <div class="card">
      <h6 class="card-header">Historial de capacitaciones</h6>
      <div class="card-body">
        <br />
        <table class="table table-bordered">
          <tr>
            <th>Ciclo</th>
            <th>Diócesis</th>
            <th>Sector</th>
            <th>Fecha</th>
            <th>Nombre</th>
            <th>Observaciones</th>
          </tr>
          <tr v-for="rol in historialEventos" :key="rol.evento_det_id">
            <td>
              <span>{{ rol.ciclo }}</span>
            </td>
            <td>
              <span>{{ rol.diocesis }}</span>
            </td>
            <td>
              <span>{{ rol.sector }}</span>
            </td>
            <td>
              <span>{{ rol.fecha }} </span>
            </td>
            <td>
              {{ rol.evento }}
            </td>
            <td>
              {{ rol.nota }}
            </td>
          </tr>
          <tr v-if="historialEventos.length == 0">
            <td colspan="6">No hay asistencia a eventos</td>
          </tr>
        </table>

      </div>
    </div>

  </div>
</template>
<script>
import PerfilMatrimonio from "@/components/PerfilMatrimonio.vue";
import PerfilJoven from "@/components/PerfilJoven.vue";
import PerfilMare from "@/components/PerfilMare.vue";
import PerfilAe from "@/components/PerfilAe.vue";
import api from "@/api";
import { Constantes } from "@/api";

export default {
  name: "Registro",
  components: { PerfilMatrimonio, PerfilJoven, PerfilMare, PerfilAe },
  data() {
    return {
      Constantes: Constantes,
      tipo: null,
      titulo: '',
      diocesis: [],
      membresia: { roles_adicionales: [] },
      roles: [],
      rolesAdicionales: [],
      todoNiveles: [],
      niveles: [],
      guardando: false,
      sectores: [],
      sectores_rol: { 0: [], 1: [], 2: [], 3: [], 4: [] },
      niveles_rol: { 0: [], 1: [], 2: [], 3: [], 4: [] },
      estatuses: [],
      historialRoles: [],
      historialEventos: [],
    };
  },
  methods: {

    listaEstatuses: function () {
      api.listarEstatusMembresia().then((r) => {
        this.estatuses = r.data;
      });
    },
    listaDiocesis: function () {
      api.listarDiocesis().then((r) => {
        this.diocesis = r.data;
      });
    },

    listaRoles: function () {
      api.listarRoles(this.membresia.tipo_membresia_id).then((r) => {
        this.roles = r.data;
        this.rolesAdicionales = this.roles.filter(r => !Constantes.EQUIPEROS.includes(r.rol_id));
      });
    },
    listaTodosNiveles: function () {
      api.listarNiveles().then((r) => {
        this.todosNiveles = r.data;
        this.listaNiveles()
      });
    },

    listaNiveles: function () {
      let niveles = []
      if (Constantes.PROMOTORES_ADOLESCENTES.includes(this.membresia.rol_id)) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE);
      } else if (this.membresia.rol_id == 985) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_JOVEN);
      } else {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == this.membresia.tipo_membresia_id);
      }

      if (niveles.filter(n => n.nivel_id == this.membresia.nivel_id).length == 0) {
        this.membresia.nivel_id = null;
      }
      this.niveles = niveles;

    },
    actualizaNivelesRol: function (pos, rol_adicional) {
      rol_adicional.nivel_id = "";
      this.niveles_rol[pos] = [];

      let niveles = []
      if (Constantes.PROMOTORES_ADOLESCENTES.includes(rol_adicional.rol_id)) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_ADOLESCENTE);
      } else if (this.membresia.rol_id == 985) {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == Constantes.TIPO_JOVEN);
      } else {
        niveles = this.todosNiveles.filter(r => r.tipo_membresia_id == this.membresia.tipo_membresia_id);
      }

      if (niveles.filter(n => n.nivel_id == this.membresia.nivel_id).length == 0) {
        rol_adicional.nivel_id = null;
      }
      this.niveles_rol[pos] = niveles;

    },
    actualizaSectores: function () {
      var id = this.membresia.diocesis_id;
      this.sectores = [];
      api.listarSectores(id).then((r) => {
        this.sectores = r.data;
      });
    },
    actualizaSectoresRol: function (pos, rol_adicional) {
      rol_adicional.sector_id = "";
      var id = rol_adicional.diocesis_id;
      this.sectores_rol[pos] = [];
      api.listarSectores(id).then((r) => {
        this.sectores_rol[pos] = r.data;
      });
    },
    obtenerMembresia: function (id) {
      api.obtenerMembresia(id).then((r) => {
        this.membresia = r.data.membresia;
        this.actualizaSectores();
        this.listaRoles();
        this.listaTodosNiveles();
      });
    },
    listarHistorialRoles: function () {
      api.listarHistorialRoles().then((r) => {
        this.historialRoles = r.data.historial;
      });
    },
    listarHistorialEventos: function () {
      api.listarHistorialEventos().then((r) => {
        this.historialEventos = r.data.historial;
      });
    },
  },
  computed: {},
  mounted: function () {
    if (!this.$store.getters.usuario) {
      this.$store.dispatch('restaurarSesion')
    }
    const usuario = this.$store.getters.usuario;

    this.tipo = usuario.tipo_membresia_id;
    this.titulo = usuario.nombre_completo;
    this.obtenerMembresia(usuario.membresia_id);
    this.listarHistorialRoles();
    this.listarHistorialEventos();
    this.listaDiocesis();
    this.listaEstatuses();
  },
};
</script>
<style scoped></style>
