import Acceso from '@/views/Acceso.vue';
import CambioContrasena from '@/views/CambioContrasena.vue';
import Registro from '@/views/Registro.vue';
import Salir from '@/views/Salir.vue';
import Perfil from '@/views/Perfil.vue';
import Pausa from '@/views/Pausa.vue';
import SinPermisos from '@/views/SinPermisos.vue';
import store from '@/store';
import Errores from '@/views/Errores.vue';

export default [
  {
    path: '/acceso',
    name: 'Acceso',
    component: Acceso,
    beforeEnter(to, from, next) {
      if (store.getters.ciclo != null) {
        next();
      } else {
        next('/pausa');
      }
    }
  },
  {
    path: '/cambiocontrasena',
    name: 'CambioContrasena',
    component: CambioContrasena
  },
  {
    path: '/registro',
    name: 'Registro',
    component: Registro,
    beforeEnter(to, from, next) {
      if (store.getters.ciclo?.permiso_inscribir) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil
  },
  {
    path: '/salir',
    name: 'Salir',
    component: Salir
  },
  {
    path: '/pausa',
    name: 'Pausa',
    component: Pausa
  },
  {
    path: '/sinpermisos',
    name: 'SinPermisos',
    component: SinPermisos
  },
  {
    path: '/errores',
    name: 'Errores',
    component: Errores,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada && store.getters.usuario.rol_admin) {
        next();
      } else {
        next('/acceso');
      }
    }
  },
];
