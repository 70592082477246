<template>
  <div>
    <div class="card">
      <h6 class="card-header">Datos generales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="parroquia">Parroquia a la que asisten</label>
            <div class="form-info">
              {{ matrimonio.parroquia }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label class="form-check-label" for="casado_civil">¿Casados por el civil?
            </label>
            <div class="form-info">
              {{ matrimonio.casados_civil ? 'Sí' : 'No' }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label class="form-check-label" for="casado_iglesia">¿Casados por la iglesia?</label>
            <div class="form-info">
              {{ matrimonio.casados_iglesia ? 'Sí, ' : 'No' }} {{ matrimonio.fecha_boda_religiosa }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <div class="card">
      <h6 class="card-header">Datos personales de él</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nombre_el">Nombre(s)</label>
                <div class="form-info">
                  {{ matrimonio.nombre_el }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop_el">Apellido paterno</label>
                <div class="form-info">
                  {{ matrimonio.apellidop_el }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom_el">Apellido materno</label>
                <div class="form-info">
                  {{ matrimonio.apellidom_el }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac_el">Fecha de nacimiento</label>
                <div class="form-info">
                  {{ matrimonio.fecha_nac_el }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ocupacion_el">Ocupación</label>
                <div class="form-info">
                  {{ matrimonio.ocupacion_el }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_el_id">Grado de estudios</label>
                <div class="form-info">
                  {{ gradoestudios.filter(s => s.grado_estudio_id == matrimonio.grado_estudio_el_id)[0]?.nom_grado }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular_el">Celular</label>
                <div class="form-info">
                  {{ matrimonio.celular_el }}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo_el">Correo electrónico</label>
                <div class="form-info">
                  {{ matrimonio.correo_el }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <img
              :src="this.matrimonio.foto_el instanceof Object ? `${API_URL}/archivo/${matrimonio.foto_el.ruta}` : matrimonio.foto_el"
              width="100%" :style="'max-width: 130px;'" class="img-responsive" />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos personales de ella</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="nombre_ella">Nombre(s)</label>
                <div class="form-info">
                  {{ matrimonio.nombre_ella }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop_ella">Apellido paterno</label>
                <div class="form-info">
                  {{ matrimonio.apellidop_ella }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom_ella">Apellido materno</label>
                <div class="form-info">
                  {{ matrimonio.apellidom_ella }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac_ella">Fecha de nacimiento</label>
                <div class="form-info">
                  {{ matrimonio.fecha_nac_ella }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ocupacion_el">Ocupación</label>
                <div class="form-info">
                  {{ matrimonio.ocupacion_ella }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_el_id">Grado de estudios</label>
                <div class="form-info">
                  {{ gradoestudios.filter(s => s.grado_estudio_id == matrimonio.grado_estudio_ella_id)[0]?.nom_grado }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular_ella">Celular</label>
                <div class="form-info">
                  {{ matrimonio.celular_ella }}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo_ella">Correo electrónico</label>
                <div class="form-info">
                  {{ matrimonio.correo_ella }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <img
              :src="this.matrimonio.foto_ella instanceof Object ? `${API_URL}/archivo/${matrimonio.foto_ella.ruta}` : matrimonio.foto_ella"
              width="100%" :style="'max-width: 130px;'" class="img-responsive" />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos de los hijos</h6>
      <div class="card-body">
        <div class="">
          <table class="table table-bordered">
            <tr>
              <th>Nombre</th>
              <th>Fecha de nacimiento</th>
            </tr>
            <tr v-for="hijo in matrimonio.hijos" :key="hijo.hijo_id">
              <td>
                {{ hijo.nombre }}
              </td>
              <td>
                {{ hijo.fecha_nac }}
              </td>
            </tr>
            <tr v-if="matrimonio.hijos.length == 0">
              <td colspan="2">No se han agregado hijos</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <div class="form-info">
              {{ matrimonio.calle }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <div class="form-info">
              {{ estados.filter(s => s.estado_id == matrimonio.estado_id)[0]?.nom_estado }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <div class="form-info">
              {{ municipios.filter(s => s.municipio_id == matrimonio.municipio_id)[0]?.nom_municipio }}
            </div>              
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <div class="form-info">
              {{ localidades.filter(s => s.localidad_id == matrimonio.localidad_id)[0]?.nom_localidad }}
            </div>              

          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <div class="form-info">
              {{ matrimonio.codigo_postal }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <div class="form-info">
              {{ matrimonio.telefono_domicilio }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { API_URL } from "@/api";
export default {
  name: "PerfilMatrimonio",
  components: {},
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_URL: API_URL,
      estados: [],
      municipios: [],
      localidades: [],
      matrimonio: { hijos: [] },
      gradoestudios: [],
    };
  },
  methods: {
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.matrimonio.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.matrimonio.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.matrimonio.localidad_id;
      let matrimonio = this.matrimonio;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          matrimonio.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaMatrimonio: function () {
      this.matrimonio.estado_id != null && this.actualizaMunicipios();
      this.matrimonio.municipio_id != null && this.actualizaLocalidades();
      this.matrimonio.localidad_id != null && this.actualizaCp();
      this.matrimonio.hijos_quitar = [];
    },
  },
  computed: {},
  mounted: function () {
    this.matrimonio = this.value;
    this.inicializaMatrimonio();
    this.listaEstados();
    this.listaGradoEstudios();
  },
};
</script>
