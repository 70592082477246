
<template>
  <div>
    <div class="card">
      <h6 class="card-header">Datos personales</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-10">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="nombre">Nombre(s)</label>
                <div class="form-info">
                  {{ mare.nombre }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidop">Apellido paterno</label>
                <div class="form-info">
                  {{ mare.apellidop }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="apellidom">Apellido materno</label>
                <div class="form-info">
                  {{ mare.apellidom }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_nac">Fecha de nacimiento</label>
                <div class="form-info">
                  {{ mare.fecha_nac }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="ocupacion">Ocupación</label>
                <div class="form-info">
                  {{ mare.ocupacion }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="grado_estudio_id">Grado de estudios</label>
                <div class="form-info"> 
                  {{ gradoestudios.filter(s => s.grado_estudio_id == mare.grado_estudio_id)[0]?.nom_grado }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="celular">Celular</label>
                <div class="form-info">
                  {{ mare.celular }}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="correo">Correo electrónico</label>
                <div class="form-info">
                  {{ mare.correo }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="parroquia">Parroquia a la que asiste</label>
                <div class="form-info">
                  {{ mare.parroquia }}
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="edo_civil">Estado Civil</label>
                <div class="form-info">
                  {{ mare.edo_civil }} 
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <img
              :src="mare.foto instanceof Object ? `${API_URL}/archivo/${mare.foto.ruta}` : mare.foto"
              width="100%" :style="'max-width: 130px;'" class="img-responsive" />
          </div>            
         
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Datos de los hijos</h6>
      <div class="card-body">
        <div class="">
          <table class="table table-bordered">
            <tr>
              <th>Nombre</th>
              <th>Fecha de nacimiento</th>

            </tr>
            <tr
              v-for="hijo in mare.hijos"
              :key="hijo.hijo_id"
            >
              <td>
                <div class="form-info">
                  {{ hijo.nombre }}
                </div>
              </td>
              <td>
                <div class="form-info">
                  {{ hijo.fecha_nac }}
                </div>
              </td>
            </tr>
            <tr v-if="mare.hijos.length == 0">
              <td colspan="2">No se han agregado hijos</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <h6 class="card-header">Domicilio particular</h6>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="calle">Calle y número</label>
            <div class="form-info">
              {{ mare.calle }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="estado_id">Estado</label>
            <div class="form-info">
              {{ estados.filter(s => s.estado_id == mare.estado_id)[0]?.nom_estado }}
            </div>            
          </div>
          <div class="form-group col-md-3">
            <label for="municipio_id">Municipio</label>
            <div class="form-info">
              {{ municipios.filter(s => s.municipio_id == mare.municipio_id)[0]?.nom_municipio }}
            </div>              
          </div>
          <div class="form-group col-md-3">
            <label for="localidad_id">Colonia</label>
            <div class="form-info">
              {{ localidades.filter(s => s.localidad_id == mare.localidad_id)[0]?.nom_localidad }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="codigo_postal">CP</label>
            <div class="form-info">
              {{ mare.codigo_postal }}
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="telefono_domicilio">Tel Casa</label>
            <div class="form-info">
              {{ mare.telefono_domicilio }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
import { API_URL } from "@/api";
export default {
  name: "PerfilMare",
  components: {  },  
  props: {
    value: Object,
    mostrarFoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_URL: API_URL,    
      estados: [],
      municipios: [],
      localidades: [],
      mare: { hijos: [] },
      gradoestudios: [],
    };
  },
  methods: {
    valido: function () {
      this.$v.mare.$touch();
      return !this.$v.mare.$invalid;
    },
    agregar_hijo: function () {
      this.mare.hijos.push({
        hijo_id: -this.mare.hijos.length,
        nombre: null,
        fecha_nac: null,
      });
    },
    quitar_hijo: function (pos) {
      if (this.mare.hijos[pos].hijo_id > 0) {
        this.mare.hijos_quitar.push(this.mare.hijos[pos].hijo_id);
      }
      this.mare.hijos.splice(pos, 1);
    },
    listaEstados: function () {
      api.listarRegistroEstados().then((r) => {
        this.estados = r.data;
      });
    },
    listaGradoEstudios: function () {
      api.listarRegistroGradosEstudio().then((r) => {
        this.gradoestudios = r.data;
      });
    },
    actualizaMunicipios: function () {
      var id = this.mare.estado_id;
      this.municipios = [];
      this.localidades = [];
      api.listarRegistroMunicipios(id).then((r) => {
        this.municipios = r.data;
      });
    },
    actualizaLocalidades: function () {
      var id = this.mare.municipio_id;
      this.localidades = [];
      api.listarRegistroLocalidades(id).then((r) => {
        this.localidades = r.data;
      });
    },
    actualizaCp: function () {
      let id = this.mare.localidad_id;
      let mare = this.mare;
      this.localidades.forEach(function (localidad) {
        if (localidad.localidad_id == id) {
          mare.codigo_postal = localidad.cp;
        }
      });
    },
    inicializaMare: function () {
      this.mare.estado_id != null && this.actualizaMunicipios();
      this.mare.municipio_id != null && this.actualizaLocalidades();
      this.mare.localidad_id != null && this.actualizaCp();
      this.mare.hijos_quitar = [];
    },
  },
  computed: {},
  mounted: function () {
    this.mare = this.value;
    this.inicializaMare();
    this.listaEstados();
    this.listaGradoEstudios();
  },
};
</script>
